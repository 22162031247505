import { addCommas } from '@common/module/replaceNumber';
import Fold from '@commonComponents/Fold/Fold';
import ModalEdit from '@commonComponents/ModalEdit/ModalEdit';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import copyText from '@standby/common-ui/module/copyText';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import st from './BusinessHome.module.scss';
import vm from './BusinessHomeVm';

function BusinessHome() {
  const location = useLocation();
  const businessId = queryString.parse(location.search).businessId;

  useEffect(() => {
    vm.load(businessId);
  }, []);

  return useObserver(() =>
    vm.business ? (
      <div className={st.businessHome}>
        <div className={st.head}>
          <div className={clsx(st.title, st.copy)} onClick={() => copyText(vm.business?.customer?.businessName)}>
            {vm.business?.customer?.businessName}
          </div>
          <div className={st.ex}>파란색 글씨를 클릭하면 바로 복사되어요.</div>
          <button
            className={clsx(st.pageArrowBtn, st.pageTopBtn)}
            type="button"
            onClick={() => vm.openAlimtalkModal(businessId)}
          >
            수동 알림톡 발송
          </button>
        </div>
        <ul className={st.contentList}>
          <li>
            <div className={st.content}>
              <div className={st.title}>고객 정보</div>
              <div className={st.content2}>
                <ul className={st.contentList}>
                  {vm.business?.customer?.businessRegistrationNumber && (
                    <li>
                      <div className={st.title}>사업자등록번호</div>
                      <div className={st.content}>
                        <span
                          className={st.copy}
                          onClick={() => copyText(vm.business?.customer?.businessRegistrationNumber)}
                        >
                          {vm.business?.customer?.businessRegistrationNumber}
                        </span>
                      </div>
                    </li>
                  )}
                  <li>
                    <div className={st.title}>법인등록번호</div>
                    <div className={st.content}>
                      <span className={st.copy} onClick={() => copyText(vm.business?.customer?.cssn)}>
                        {vm.business?.customer?.cssn}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className={st.title}>담당자</div>
                    <div className={st.content}>
                      <ul className={st.userList}>
                        {vm.business?.customer?.managers.slice(0, 4).map((manager, index) => (
                          <li key={index}>
                            <span className={st.copy} onClick={() => copyText(manager?.name)}>
                              {manager?.name}
                            </span>
                            {manager.duplicate === true && <span>[중복]</span>}
                            <span className={clsx(st.position, manager.position ? null : st.empty)}>
                              {manager.position ? manager.position : '역할 입력'}
                              <ModalEdit
                                inputType="text"
                                value={manager.position}
                                type={`managerPosition|${manager.id}`}
                              />
                            </span>
                            {manager?.phoneNumber && (
                              <span className={st.copy} onClick={() => copyText(manager?.phoneNumber)}>
                                {manager?.phoneNumber}
                              </span>
                            )}
                            {manager?.email && (
                              <span className={st.copy} onClick={() => copyText(manager?.email)}>
                                {manager?.email}
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                      {vm.business?.customer?.managers.length > 4 && (
                        <Fold text="더보기" style={{ marginTop: '10px' }}>
                          <ul className={st.userList}>
                            {vm.business?.customer?.managers.slice(4).map((manager, index) => (
                              <li key={index}>
                                <span className={st.copy} onClick={() => copyText(manager?.name)}>
                                  {manager?.name}
                                </span>
                                <span className={clsx(st.position, manager.position ? null : st.empty)}>
                                  {manager.position ? manager.position : '역할 입력'}
                                  <ModalEdit
                                    inputType="text"
                                    value={manager.position}
                                    type={`managerPosition|${manager.id}`}
                                  />
                                </span>
                                {manager?.phoneNumber && (
                                  <span className={st.copy} onClick={() => copyText(manager?.phoneNumber)}>
                                    {manager?.phoneNumber}
                                  </span>
                                )}
                                {manager?.email && (
                                  <span className={st.copy} onClick={() => copyText(manager?.email)}>
                                    {manager?.email}
                                  </span>
                                )}
                              </li>
                            ))}
                          </ul>
                        </Fold>
                      )}
                    </div>
                  </li>
                  <li>
                    <div className={st.title}>고객유형</div>
                    <div className={st.content}>
                      <span>
                        {vm.business?.customer?.bookkeepingService?.customerType
                          ? vm.business?.customer?.bookkeepingService?.customerType.replace('_', '+')
                          : '-'}
                      </span>
                    </div>
                  </li>
                </ul>
                <div
                  className={clsx(
                    st.customerMoreView,
                    vm.business?.customer?.delegationRegistrationYn === false ? st.disabled : null,
                    vm.state.customerMoreViewOpened ? st.opened : null,
                  )}
                >
                  <button
                    type="button"
                    disabled={!vm.business?.customer?.delegationRegistrationYn}
                    onClick={() => (vm.state.customerMoreViewOpened = !vm.state.customerMoreViewOpened)}
                  >
                    고객 상세정보 확인하기
                  </button>
                  {vm.state.customerMoreViewOpened && (
                    <div className={st.customerMoreViewContent}>
                      <ul>
                        <li className={st.listTitle}>법인정보</li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>1.</span>
                            <span>사업체명</span>
                          </div>
                          <div className={st.content}>{vm.business?.customer?.businessName}</div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>2.</span>
                            <span>대표자 직책 및 성명</span>
                          </div>
                          <div className={clsx(st.content, st.personList)}>
                            {vm.business?.customer?.representative.map((item, index) => (
                              <span className={st.person} key={index}>
                                {item}
                              </span>
                            ))}
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>3.</span>
                            <span>본점주소</span>
                          </div>
                          <div className={st.content}>{vm.business?.customer?.address}</div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>4.</span>
                            <span>구분1</span>
                          </div>
                          <div className={st.content}>{vm.business?.customer?.businessType}</div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>5.</span>
                            <span>구분2</span>
                          </div>
                          <div className={st.content}>
                            <span className={st.editSide}>
                              {vm.business?.customer?.taxationYn === true && '과세'}
                              {vm.business?.customer?.taxationYn === false && '면세'}
                            </span>
                            <ModalEdit
                              inputType="select"
                              value={vm.business?.customer?.taxationYn}
                              type="taxationYn"
                              options={[
                                { text: '과세', value: true },
                                { text: '면세', value: false },
                              ]}
                            />
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>6.</span>
                            <span>구분3</span>
                          </div>
                          <div className={st.content}>
                            <span className={st.editSide}>
                              {vm.business?.customer?.faithfulnessTaxPayerYn === true && '성실'}
                              {vm.business?.customer?.faithfulnessTaxPayerYn === false && '일반'}
                            </span>
                            <ModalEdit
                              inputType="select"
                              value={vm.business?.customer?.faithfulnessTaxPayerYn}
                              type="faithfulnessTaxPayerYn"
                              options={[
                                { text: '성실', value: true },
                                { text: '일반', value: false },
                              ]}
                            />
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>7.</span>
                            <span>법인등록번호</span>
                          </div>
                          <div className={st.content}>{vm.business?.customer?.cssn}</div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>8.</span>
                            <span>자본금</span>
                          </div>
                          <div className={st.content}>{addCommas(vm.business?.customer?.capital)}원</div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>9.</span>
                            <span>지점유무</span>
                          </div>
                          <div className={st.content}>{vm.business?.customer?.branch ? 'O' : 'X'}</div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>10.</span>
                            <span>법인설립일자</span>
                          </div>
                          <div className={st.content}>{vm.business?.customer?.establishedAt}</div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>11.</span>
                            <span>사업개시일</span>
                          </div>
                          <div className={st.content}>
                            <span className={st.editSide}>
                              {vm.business?.customer?.businessRegistration?.businessStartAt}
                            </span>
                          </div>
                          <ModalEdit
                            inputType="date"
                            value={vm.business?.customer?.businessRegistration?.businessStartAt}
                            type="businessStartAt"
                          />
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>12.</span>
                            <span>등기부등본</span>
                          </div>
                          <div className={st.content}>
                            <span className={vm.business?.customer?.companyCopyFile}>
                              다운로드
                              <button
                                type="button"
                                className={st.downloadLink}
                                onClick={() => vm.fileDown(vm.business?.customer?.companyCopyFile)}
                              />
                            </span>
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>13.</span>
                            <span>사업자등록증</span>
                          </div>
                          <div className={st.content}>
                            {vm.business?.customer?.businessRegistration?.unregisteredYn === true ? (
                              <div>
                                사업자등록증 미등록상태{' '}
                                <button
                                  type="button"
                                  className={st.pageArrowBtn}
                                  onClick={() => vm.businessRegistrationModalOpen()}
                                >
                                  사업자등록정보 변경
                                </button>
                              </div>
                            ) : (
                              <ul className={st.detailItemList}>
                                <li>
                                  <span
                                    className={
                                      vm.business?.customer?.businessRegistration?.businessRegistrationFile?.url
                                    }
                                  >
                                    <span>다운로드</span>
                                    <button
                                      type="button"
                                      className={st.downloadLink}
                                      onClick={() =>
                                        vm.fileDown(
                                          vm.business?.customer?.businessRegistration?.businessRegistrationFile?.url,
                                        )
                                      }
                                    />
                                  </span>
                                  <button
                                    type="button"
                                    className={st.pageArrowBtn}
                                    onClick={() => vm.businessRegistrationModalOpen()}
                                  >
                                    사업자등록정보 변경
                                  </button>
                                </li>
                                <li className={st.flexList}>
                                  <span className={st.title}>사업자등록번호</span>
                                  <span>{vm.business?.customer?.businessRegistration?.businessRegistrationNumber}</span>
                                </li>
                                <li className={st.flexList}>
                                  <span className={st.title}>
                                    업태, 종목, <br /> 업종코드
                                  </span>
                                  <ul className={st.businessTypeAndItemList}>
                                    {vm.business?.customer?.businessRegistration?.businessTypeAndItemList.map(
                                      (businessTypeAndItemList, index) => (
                                        <li key={index}>
                                          <div>
                                            <span className={st.index}>{index + 1}.</span>업태 :{' '}
                                            {businessTypeAndItemList.type}
                                          </div>
                                          <div style={{ paddingLeft: '22px' }}>
                                            종목 : {businessTypeAndItemList.item}
                                          </div>
                                          <div style={{ paddingLeft: '22px' }}>
                                            업종코드 : {businessTypeAndItemList.code}
                                          </div>
                                        </li>
                                      ),
                                    )}
                                  </ul>
                                </li>
                              </ul>
                            )}
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>14.</span>
                            <span>주주명부</span>
                          </div>
                          <div className={st.content}>
                            <span
                              className={
                                vm.business?.customer?.shareholder?.shareholderListPDFFile ? null : st.downloadDisabled
                              }
                            >
                              PDF 다운로드
                              <button
                                type="button"
                                className={st.downloadLink}
                                onClick={() => vm.fileDown(vm.business?.customer?.shareholder?.shareholderListPDFFile)}
                              />
                            </span>
                            <span
                              className={
                                vm.business?.customer?.shareholder?.shareholderListExcelFile
                                  ? null
                                  : st.downloadDisabled
                              }
                              style={{ marginLeft: '16px' }}
                            >
                              엑셀 다운로드
                              <button
                                type="button"
                                className={st.downloadLink}
                                onClick={() =>
                                  vm.fileDown(vm.business?.customer?.shareholder?.shareholderListExcelFile)
                                }
                              />
                            </span>
                            <button
                              type="button"
                              className={st.pageArrowBtn}
                              onClick={() => vm.stockholderRegisterRequestModalOpen()}
                            >
                              최신 주주명부 요청
                            </button>
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li className={st.listTitle}>기장계약 정보</li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>1.</span>
                            <span>기장 직원</span>
                          </div>
                          <div className={st.content}>
                            <span className={st.editSide}>
                              {vm.business?.customer?.bookkeepingService?.staff?.name}
                            </span>
                          </div>
                          <ModalEdit
                            inputType="select"
                            value={vm.business?.customer?.bookkeepingService?.staff?.value}
                            type="staff"
                            options={vm.business?.customer?.staffList.map((selectList) => {
                              return {
                                text: selectList.name,
                                value: selectList.value,
                              };
                            })}
                          />
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>2.</span>
                            <span>소속 기장팀</span>
                          </div>
                          <div className={st.content}>{vm.business?.customer?.bookkeepingService?.team}</div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>3.</span>
                            <span>고객유형</span>
                          </div>
                          <div className={clsx(st.content, st.fullWidth)}>
                            <ul className={st.detailItemList}>
                              <li>
                                <span className={st.editSide}>
                                  {vm.business?.customer?.bookkeepingService?.customerType.replace('_', '+')}
                                </span>
                                <ModalEdit
                                  inputType="select"
                                  value={vm.business?.customer?.bookkeepingService?.customerType}
                                  type="customerType"
                                  options={[
                                    { text: '사업자등록', value: '사업자등록' },
                                    { text: '신고대행', value: '신고대행' },
                                    { text: '기장대행+원천세X', value: '기장대행_원천세X' },
                                    { text: '기장대행+원천세O', value: '기장대행_원천세O' },
                                  ]}
                                />
                              </li>
                              {vm.business?.customer?.bookkeepingService?.customerTypeChangeHistories?.length > 0 && (
                                <li>
                                  <Table className={st.detailTable}>
                                    <thead>
                                      <tr>
                                        <th>변경일자</th>
                                        <th>변경 전 고객유형</th>
                                        <th>변경 후 고객유형</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {vm.business.customer.bookkeepingService.customerTypeChangeHistories.map(
                                        (history, index) => (
                                          <tr key={index}>
                                            <td>{history?.changedAt}</td>
                                            <td>{history?.previousCustomerType}</td>
                                            <td>{history?.newCustomerType}</td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </Table>
                                </li>
                              )}
                            </ul>
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>4.</span>
                            <span>월 기장료</span>
                          </div>
                          <div className={clsx(st.content, st.fullWidth)}>
                            <ul className={st.detailItemList}>
                              <li>
                                <span>
                                  {addCommas(vm.business?.customer?.bookkeepingService?.bookkeepingServiceFee)}원
                                </span>
                                <button
                                  type="button"
                                  className={st.pageArrowBtn}
                                  onClick={() => vm.bookKeepingFeeChangeModalOpen()}
                                >
                                  변경하기
                                </button>
                              </li>
                              {vm.business?.customer?.bookkeepingService?.bookkeepingServiceFeeChangeHistories?.length >
                                0 && (
                                <li>
                                  <Table className={st.detailTable}>
                                    <thead>
                                      <tr>
                                        <th>변경일자</th>
                                        <th>변경 전 금액</th>
                                        <th>변경 후 금액</th>
                                        <th>계약서 새로작성</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {vm.business.customer.bookkeepingService.bookkeepingServiceFeeChangeHistories.map(
                                        (bookkeepingServiceFeeChangeHistory, index) => (
                                          <tr key={index}>
                                            <td>{bookkeepingServiceFeeChangeHistory?.changedAt}</td>
                                            <td>{addCommas(bookkeepingServiceFeeChangeHistory?.previousFee)} 원</td>
                                            <td>{addCommas(bookkeepingServiceFeeChangeHistory?.newFee)} 원</td>
                                            <td>{bookkeepingServiceFeeChangeHistory?.newContractFileType}</td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </Table>
                                </li>
                              )}
                            </ul>
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>5.</span>
                            <span>기장계약 개시일</span>
                          </div>
                          <div className={st.content}>
                            <span className={st.editSide}>
                              {vm.business?.customer?.bookkeepingService?.bookkeepingServiceStartAt}
                            </span>
                          </div>
                          <ModalEdit
                            inputType="date"
                            value={vm.business?.customer?.bookkeepingService?.bookkeepingServiceStartAt}
                            type="bookkeepingServiceStartAt"
                          />
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>6.</span>
                            <span>무료기장혜택 종료일</span>
                          </div>

                          <div className={clsx(st.content, st.fullWidth)}>
                            <ul className={st.detailItemList}>
                              <li>
                                <span>
                                  {vm.business?.customer?.bookkeepingService?.freeBookkeepingServiceEndAt || '-'}
                                </span>
                                {vm.business?.customer?.bookkeepingService?.freeBookkeepingServiceEndAt && (
                                  <button
                                    type="button"
                                    className={st.pageArrowBtn}
                                    onClick={() => vm.freeBookKeepingServiceExtensionModalOpen()}
                                  >
                                    연장하기
                                  </button>
                                )}
                              </li>
                              {vm.business?.customer?.bookkeepingService?.freeBookkeepingServiceChangeHistories
                                ?.length > 0 && (
                                <li>
                                  <Table className={st.detailTable}>
                                    <thead>
                                      <tr>
                                        <th>연장일자</th>
                                        <th>기존 종료일</th>
                                        <th>변경 종료일</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {vm.business.customer.bookkeepingService.freeBookkeepingServiceChangeHistories.map(
                                        (history, index) => (
                                          <tr key={index}>
                                            <td>{history?.extensionDate}</td>
                                            <td>{history?.originalEndDate}</td>
                                            <td>{history?.changedEndDate}</td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </Table>
                                </li>
                              )}
                            </ul>
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>7.</span>
                            <span>CMS 계약서 동의일자</span>
                          </div>
                          <div className={st.content}>
                            {vm.business?.customer?.bookkeepingService?.cmsAccount?.cmsAccountAcceptAt || '-'}
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>8.</span>
                            <span>기장계약서</span>
                          </div>
                          <div className={st.content}>
                            <span
                              className={
                                vm.business?.customer?.bookkeepingService?.bookkeepingContractFile
                                  ? null
                                  : st.downloadDisabled
                              }
                            >
                              다운로드
                              <button
                                type="button"
                                className={st.downloadLink}
                                onClick={() =>
                                  vm.fileDown(vm.business?.customer?.bookkeepingService?.bookkeepingContractFile)
                                }
                              />
                            </span>
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>9.</span>
                            <span>CMS 계약서</span>
                          </div>
                          <div className={st.content}>
                            <ul className={st.detailItemList}>
                              <li>
                                <span
                                  className={
                                    vm.business?.customer?.bookkeepingService?.cmsAccount?.cmsAccountFile
                                      ? null
                                      : st.downloadDisabled
                                  }
                                >
                                  계약서 다운로드
                                  <button
                                    type="button"
                                    className={st.downloadLink}
                                    onClick={() =>
                                      vm.fileDown(vm.business?.customer?.bookkeepingService?.cmsAccount?.cmsAccountFile)
                                    }
                                  />
                                </span>
                              </li>
                              <li>
                                <span
                                  className={
                                    vm.business?.customer?.bookkeepingService?.cmsAccount?.bankbook?.fileDownloadUrl
                                      ? null
                                      : st.downloadDisabled
                                  }
                                >
                                  통장사본 다운로드
                                  <button
                                    type="button"
                                    className={st.downloadLink}
                                    onClick={() =>
                                      vm.fileDown(
                                        vm.business?.customer?.bookkeepingService?.cmsAccount?.bankbook
                                          ?.fileDownloadUrl,
                                      )
                                    }
                                  />
                                </span>
                              </li>
                              {vm.business?.customer?.bookkeepingService?.cmsAccount?.bankbook?.bankName && (
                                <li>
                                  <span
                                    className={st.copy}
                                    onClick={() =>
                                      copyText(
                                        vm.business?.customer?.bookkeepingService?.cmsAccount?.bankbook?.bankName,
                                      )
                                    }
                                  >
                                    {vm.business?.customer?.bookkeepingService?.cmsAccount?.bankbook?.bankName}
                                  </span>
                                  <span
                                    className={st.copy}
                                    onClick={() =>
                                      copyText(
                                        vm.business?.customer?.bookkeepingService?.cmsAccount?.bankbook?.accountNumber,
                                      )
                                    }
                                  >
                                    {vm.business?.customer?.bookkeepingService?.cmsAccount?.bankbook?.accountNumber}
                                  </span>
                                  <CopyBtn
                                    className={st.copyBtn}
                                    copyText={
                                      vm.business?.customer?.bookkeepingService?.cmsAccount?.bankbook?.bankName +
                                      ' ' +
                                      vm.business?.customer?.bookkeepingService?.cmsAccount?.bankbook?.accountNumber
                                    }
                                  >
                                    계좌전체 복사
                                  </CopyBtn>
                                </li>
                              )}
                            </ul>
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>10.</span>
                            <span>기장계약 일시정지</span>
                          </div>
                          <div className={clsx(st.content, st.fullWidth)}>
                            <ul className={st.detailItemList}>
                              <li>
                                {vm.business?.customer?.bookkeepingService?.isBookkeepingServiceActive === true ? (
                                  <button
                                    type="button"
                                    className={st.pageArrowBtn}
                                    onClick={() => vm.bookKeepingServicePauseRequestModalOpen()}
                                    style={{ marginLeft: '0' }}
                                  >
                                    정지하기
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className={st.pageArrowBtn}
                                    onClick={() => vm.bookKeepingServiceUnfreezeRequestModalOpen()}
                                    style={{ marginLeft: '0' }}
                                  >
                                    해제하기
                                  </button>
                                )}
                              </li>
                              {vm.business?.customer?.bookkeepingService?.statusTypeHistories?.length > 0 && (
                                <li>
                                  <Table className={st.detailTable}>
                                    <thead>
                                      <tr>
                                        <th>정지 요청일</th>
                                        <th>정지 시작일</th>
                                        <th>정지 해제일</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {vm.business.customer.bookkeepingService.statusTypeHistories.map(
                                        (history, index) => (
                                          <tr key={index}>
                                            <td>{history?.requestedAt}</td>
                                            <td>{history?.pauseAt}</td>
                                            <td>{history?.resumedAt}</td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </Table>
                                </li>
                              )}
                            </ul>
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>11.</span>
                            <span>기장계약 해지</span>
                          </div>
                          <div className={st.content}>
                            {vm.business?.customer?.contractEndDate && (
                              <span className={st.editSide}>{vm.business?.customer?.contractEndDate} 해지예정</span>
                            )}
                            {vm.business?.customer?.contractEndDate ? (
                              <button
                                type="button"
                                className={st.pageArrowBtn}
                                onClick={() => vm.cancelBookkeepingContract(businessId)}
                              >
                                해지 취소하기
                              </button>
                            ) : (
                              <button
                                type="button"
                                className={st.pageArrowBtn}
                                onClick={() => vm.avoidBookkeepingContractModalOpen()}
                                style={{ marginLeft: '0' }}
                              >
                                해지하기
                              </button>
                            )}
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li className={st.listTitle}>회계정보</li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>1.</span>
                            <span>회계연도</span>
                          </div>
                          <div className={st.content}>{vm.business?.customer?.accountingService?.fiscalYear}</div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>2.</span>
                            <span>
                              전 반기 매출부가세 합계액
                              <br />({vm.business?.customer?.accountingService?.reportPeriod})
                            </span>
                          </div>
                          <div className={st.content}>
                            {addCommas(vm.business?.customer?.accountingService?.halfTotalOutputTax) || '- '}원
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>3.</span>
                            <span>
                              전 반기 부가세 납부세액
                              <br />({vm.business?.customer?.accountingService?.reportPeriod})
                            </span>
                          </div>
                          <div className={st.content}>
                            {addCommas(vm.business?.customer?.accountingService?.halfNationalTaxPayable) || '- '}원
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>4.</span>
                            <span>
                              {vm.business?.customer?.accountingService?.beforePreliminaryReturnType.name}
                              <br />
                              진행여부
                            </span>
                          </div>
                          <div className={st.content}>
                            <span className={st.editSide}>
                              {vm.business?.customer?.accountingService?.beforePreliminaryReturnType.value || '-'}
                            </span>
                            <ModalEdit
                              inputType="selectLong"
                              value={vm.business?.customer?.accountingService?.beforePreliminaryReturnType.value}
                              type={`valueAddedTax|${vm.business?.customer?.accountingService?.beforePreliminaryReturnType.year}|${vm.business?.customer?.accountingService?.beforePreliminaryReturnType.period}`}
                              options={vm.business?.customer?.accountingService?.beforePreliminaryReturnType.selectList.map(
                                (selectList) => {
                                  return {
                                    text: selectList.name,
                                    value: selectList.value,
                                  };
                                },
                              )}
                            />
                          </div>
                        </li>
                        <li className={st.listItem}>
                          <div className={st.title}>
                            <span className={st.number}>5.</span>
                            <span>
                              {vm.business?.customer?.accountingService?.currentPreliminaryReturnType.name}
                              <br />
                              진행여부
                            </span>
                          </div>
                          <div className={st.content}>
                            <span className={st.editSide}>
                              {vm.business?.customer?.accountingService?.currentPreliminaryReturnType.value || '-'}
                            </span>
                            <ModalEdit
                              inputType="selectLong"
                              value={vm.business?.customer?.accountingService?.currentPreliminaryReturnType.value}
                              type={`valueAddedTax|${vm.business?.customer?.accountingService?.currentPreliminaryReturnType.year}|${vm.business?.customer?.accountingService?.currentPreliminaryReturnType.period}`}
                              options={vm.business?.customer?.accountingService?.currentPreliminaryReturnType.selectList.map(
                                (selectList) => {
                                  return {
                                    text: selectList.name,
                                    value: selectList.value,
                                  };
                                },
                              )}
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className={st.content}>
              <div className={st.title}>진행 상황</div>
              <div className={st.content2}>
                <ul className={st.progressList}>
                  {vm.business?.progress?.valueAddedTax && (
                    <li>
                      <div className={st.title}>
                        <a className={st.link} href="/valueAddedTax/list" target="_blank">
                          부가세
                        </a>
                        <select
                          value={vm.business?.progress?.valueAddedTax?.selectList?.selectedIndex}
                          onChange={(e) => vm.valueAddedTaxSelectedIndex(e, businessId)}
                          className={st.selectedSmall}
                          style={{ width: '300px' }}
                        >
                          {vm.business?.progress?.valueAddedTax?.selectList?.periodList.map((period, index) => (
                            <option key={index} value={index}>
                              {period.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={st.progressItemList}>
                        {vm.business?.progress?.valueAddedTax?.steps.map((step, index) => (
                          <div className={clsx(st.item, step?.selectedYn ? st.selected : null)} key={index}>
                            <div className={st.status}>{step?.type}</div>
                            <>
                              <div className={st.date}>{step?.dateTime}</div>
                              <div className={st.trueStatue}>{step?.tag}</div>
                            </>
                          </div>
                        ))}
                      </div>
                    </li>
                  )}
                  {vm.business?.progress?.businessRegistration && (
                    <li>
                      <div className={st.title}>
                        <a
                          className={st.link}
                          href="#"
                          onClick={(e) => vm.openBusinessRegistrationProcessing(e, businessId)}
                          target="_blank"
                        >
                          사업자등록
                        </a>
                      </div>
                      <div className={st.progressItemList}>
                        {vm.business?.progress?.businessRegistration?.steps.map((step, index) => (
                          <div className={clsx(st.item, step?.selectedYn ? st.selected : null)} key={index}>
                            <div className={st.status}>{step?.type}</div>
                            <>
                              <div className={st.date}>{step?.dateTime}</div>
                              <div className={st.trueStatue}>{step?.tag}</div>
                            </>
                          </div>
                        ))}
                      </div>
                    </li>
                  )}
                  {vm.business?.progress?.corporateTax && (
                    <li>
                      <div className={st.title}>
                        <a className={st.link} href="/corporateTax/list" target="_blank">
                          법인세
                        </a>
                        <select
                          value={vm.business?.progress?.corporateTax?.selectList?.selectedIndex}
                          onChange={(e) => vm.corporateTaxTaxSelectedIndex(e, businessId)}
                          className={st.selectedSmall}
                          style={{ width: '300px' }}
                        >
                          {vm.business?.progress?.corporateTax?.selectList?.periodList.map((period, index) => (
                            <option key={index} value={index}>
                              {period.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={st.progressItemList}>
                        {vm.business?.progress?.corporateTax?.steps.map((step, index) => (
                          <div
                            className={clsx(
                              st.item,
                              step?.selectedYn ? st.selected : null,
                              vm.business?.progress?.corporateTax?.steps.length > 4 ? st.minWidth : null,
                            )}
                            key={index}
                          >
                            <div className={st.status}>{step?.type}</div>
                            <>
                              <div className={st.date}>{step?.dateTime}</div>
                              <div className={st.trueStatue}>{step?.tag}</div>
                            </>
                          </div>
                        ))}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    ) : (
      <></>
    ),
  );
}

export default BusinessHome;
