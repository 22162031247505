import { toJS, observable } from 'mobx';
import BusinessHomeModel from '@model/BusinessHomeModel';
import BusinessHomeService from '@service/BusinessHomeService';

class BusinessRegistrationModalVm {
  constructor() {
    this.businessRegistration = observable(toJS(BusinessHomeModel.business.customer.businessRegistration));
    this.alimtalkToList = observable(
      toJS(BusinessHomeModel.business.customer.alimtalkToList.BUSINESS_REGISTRATION_FILE_CHANGED),
    );
  }
  unregisteredYn(e) {
    this.businessRegistration.unregisteredYn = e.target.checked;
  }
  businessRegistrationNumber(e) {
    this.businessRegistration.businessRegistrationNumber = e.target.value.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
  }
  businessStartAt(e) {
    let date = null;
    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);
      date = year + '-' + month + '-' + day;
    }
    this.businessRegistration.businessStartAt = date;
  }
  issuedAt(e) {
    let date = null;
    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);
      date = year + '-' + month + '-' + day;
    }
    this.businessRegistration.issuedAt = date;
  }
  addBusinessTypeAndItemList(index) {
    this.businessRegistration.businessTypeAndItemList.push({
      type: '',
      item: '',
    });
  }
  removeBusinessTypeAndItemList(index) {
    this.businessRegistration.businessTypeAndItemList.splice(index, 1);
  }
  businessType(e, index) {
    this.businessRegistration.businessTypeAndItemList[index].type = e.target.value;
  }
  businessItems(e, index) {
    this.businessRegistration.businessTypeAndItemList[index].item = e.target.value;
  }
  businessCode(e, index) {
    this.businessRegistration.businessTypeAndItemList[index].code = e.target.value;
  }
  changeBusinessRegistrationFile(file) {
    this.businessRegistration.businessRegistrationFile = file;
  }
  changeAlimtalkToList(e, index) {
    this.alimtalkToList[index].checkedYn = e.target.checked;
  }
  get saveActive() {
    let active = true;
    if (!this.businessRegistration.unregisteredYn) {
      //사업자 등록되어있을때만 체크
      if (!this.businessRegistration.businessRegistrationNumber) {
        //사업자등록번호
        active = false;
      }
      if (!this.businessRegistration.businessStartAt) {
        //사업개시일
        active = false;
      }
      if (!this.businessRegistration.issuedAt) {
        //사업자등록증 발급일
        active = false;
      }
      if (this.businessRegistration.businessTypeAndItemList.length === 0) {
        active = false;
      }
      if (this.businessRegistration.businessRegistrationFile === null) {
        active = false;
      }
    }
    const checkedYn = this.alimtalkToList.some((person) => {
      //알림톡 받으실분
      return person.checkedYn === true;
    });
    if (checkedYn === false) {
      active = false;
    }
    return active;
  }
  get param() {
    let param = {};
    if (this.businessRegistration.unregisteredYn === true) {
      param.businessRegistration = {
        unregisteredYn: this.businessRegistration.unregisteredYn,
      };
    } else {
      param.businessRegistration = {
        businessRegistrationNumber: this.businessRegistration.businessRegistrationNumber,
        unregisteredYn: this.businessRegistration.unregisteredYn,
        businessStartAt: this.businessRegistration.businessStartAt,
        issuedAt: this.businessRegistration.issuedAt,
        businessTypeAndItemList: this.businessRegistration.businessTypeAndItemList,
      };
      if (
        this.businessRegistration.businessRegistrationFile &&
        !this.businessRegistration.businessRegistrationFile.url
      ) {
        param.businessRegistration.businessRegistrationFile = this.businessRegistration.businessRegistrationFile;
      }
    }
    param.alimTalkTargets = this.alimtalkToList
      .filter((person) => {
        return person.checkedYn === true;
      })
      .map((checkedPerson) => {
        return checkedPerson.id;
      });
    return param;
  }
  save(businessId) {
    BusinessHomeService.saveBusinessRegistration(businessId, this.param);
  }
  send(businessId) {
    BusinessHomeService.alimtalkBusinessRegistration(businessId, this.param);
  }
}
export default BusinessRegistrationModalVm;
