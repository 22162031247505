import { observable } from 'mobx';

const BusinessRegistrationModel = observable({
  businessRegistration: {
    companyName: '',
    corpType: '',
    businessOwners: [],
    headOfficeAddress: '',
    telephoneNo: '',
    cssn: '',
    capital: null,
    businessYear: '',
    establishedAt: '',
    dateOfBusinessCommencementByCustomer: '',
    typeOfBusinesses: [],
    representativeFamilyRelationships: [],
    fileUrl: {
      aoaFileUrl: null,
      copyFileUrl: null,
      corporateLeaseAgreementFileUrl: null,
      permitFileUrl: null,
      representativeIDFileUrl: null,
      stockholderListFileUrl: null,
    },
    certificateOfRegisteredSealUrl: '',
  },
  businessRegistrationProcessing: {
    status: null,
    taxCertificateFile: null,
    taxCertificateAlimTalkToList: [],
    businessRegistrationNumber: '',
    dateOfBusinessCommencementByAccounting: null,
    businessTypeAndItemList: [],
    businessRegistrationCompletionFile: null,
    businessRegistrationCompletionAlimTalkToList: [],
    viewingPeriod: '',
    viewingPeriodExtensionYn: false,
    issuedAt: '',
    customerDirectReceiptYn: false,
    hometaxDelegationAcceptanceYn: null,
  },
  setBusinessRegistration(data) {
    this.businessRegistration = {
      companyName: data?.companyName || '',
      corpType: data?.corpType || '',
      businessOwners: data.businessOwners.map((item) => {
        return {
          uniqueInfo: item?.uniqueInfo ?? '',
          name: item?.name || '',
          position: item?.position || '',
        };
      }),
      headOfficeAddress: data?.headOfficeAddress || '',
      telephoneNo: data?.telephoneNo || '',
      cssn: data?.cssn || '',
      capital: data?.capital ?? null,
      businessYear: data?.businessYear || null,
      establishedAt: this.setDateText(data?.establishedAt),
      dateOfBusinessCommencementByCustomer: this.setDateText(data?.dateOfBusinessCommencementByCustomer),
      typeOfBusinesses: data?.typeOfBusinesses.map((typeOfBusiness) => {
        return {
          businessPurpose: typeOfBusiness?.businessPurpose || '',
          description: typeOfBusiness?.description || '',
        };
      }),
      representativeFamilyRelationships: data?.representativeFamilyRelationships.map(
        (representativeFamilyRelationship) => {
          return {
            name: representativeFamilyRelationship?.name || '',
            relationship: representativeFamilyRelationship?.relationship || '',
          };
        },
      ),
      fileUrl: {
        aoaFileUrl: data?.fileUrl?.aoaFileUrl || null,
        copyFileUrl: data?.fileUrl?.copyFileUrl || null,
        corporateLeaseAgreementFileUrl: data?.fileUrl?.corporateLeaseAgreementFileUrl || null,
        permitFileUrl: data?.fileUrl?.permitFileUrl || null,
        representativeIDFileUrl: data?.fileUrl?.representativeIDFileUrl || null,
        stockholderListFileUrl: data?.fileUrl?.stockholderListFileUrl || null,
      },
      certificateOfRegisteredSealUrl: data?.certificateOfRegisteredSealUrl || null,
    };
  },
  setBusinessRegistrationProcessing(data) {
    this.businessRegistrationProcessing = {
      status: data?.status || null,
      taxCertificateFile: data?.taxCertificateFile || null,
      taxCertificateAlimTalkToList: data.taxCertificateAlimTalkToList.map((item) => {
        return {
          checkedYn: item?.checkedYn || false,
          id: item?.id ?? null,
          name: item?.name || '',
          position: item?.position || '',
        };
      }),
      businessRegistrationNumber: data?.businessRegistrationNumber || '',
      dateOfBusinessCommencementByAccounting: this.setDate(data?.dateOfBusinessCommencementByAccounting),
      businessTypeAndItemList:
        data?.businessTypeAndItemList.length !== 0
          ? data?.businessTypeAndItemList.map((businessTypeAndItem) => {
              return {
                type: businessTypeAndItem.type,
                item: businessTypeAndItem.item,
                code: businessTypeAndItem.code,
              };
            })
          : [
              {
                type: '',
                item: '',
                code: '',
              },
            ],
      businessRegistrationCompletionFile: data?.businessRegistrationCompletionFile || null,
      businessRegistrationCompletionAlimTalkToList: data.businessRegistrationCompletionAlimTalkToList.map((item) => {
        return {
          checkedYn: item?.checkedYn || false,
          id: item?.id ?? null,
          name: item?.name || '',
          position: item?.position || '',
        };
      }),
      viewingPeriod: data?.viewingPeriod || '',
      viewingPeriodExtensionYn: data?.viewingPeriodExtensionYn || false,
      issuedAt: data?.issuedAt || '',
      customerDirectReceiptYn: data?.customerDirectReceiptYn || false,
      hometaxDelegationAcceptanceYn: data?.hometaxDelegationAcceptanceYn ?? null,
    };
  },
  setDate(date) {
    if (date) {
      return date.year + '-' + date.month + '-' + date.day;
    } else {
      return null;
    }
  },
  setDateText(date) {
    if (date) {
      return date.year + '년 ' + date.month + '월 ' + date.day + '일';
    } else {
      return null;
    }
  },
});

export default BusinessRegistrationModel;
