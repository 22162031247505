import partner from '@common/module/partner';
import { addCommas } from '@common/module/replaceNumber';
import listSt from '@commonComponents/CheckResultList/CheckResultList.module.scss';
import DragFileUpload from '@commonComponents/DragFileUpload/DragFileUpload';
import MessageHint from '@commonComponents/MessageHint/MessageHint';
import MinusBtn from '@standby/common-ui/components/atoms/Button/MinusBtn/MinusBtn';
import PlusBtn from '@standby/common-ui/components/atoms/Button/PlusBtn/PlusBtn';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import st from './Process.module.scss';
import vm from './ProcessVm';

function Process() {
  const location = useLocation();
  const businessId = queryString.parse(location.search).businessId;

  useEffect(() => {
    if (businessId) {
      vm.dataLoad(businessId);
    }
  }, []);

  return useObserver(() =>
    businessId ? (
      vm.process ? (
        <div className={listSt.checkResultList}>
          <div className={listSt.result}>
            <div className={clsx(listSt.title, st.pageTitle)}>
              이용등록
              <button
                className={clsx(listSt.pageArrowBtn, st.pageTopBtn)}
                type="button"
                onClick={() => vm.openAlimtalkModal(businessId)}
              >
                수동 알림톡 발송
              </button>
            </div>
            <ul>
              <li>
                <div className={listSt.ListTitle} style={{ width: '190px' }}>
                  법인조회
                </div>
                <div className={clsx(listSt.listContent, st.businessCompanyInfo)}>
                  <div className={st.companyName}>{vm.process.companyName}</div>
                  <div>{vm.process.address}</div>
                  <button
                    className={clsx(listSt.pageArrowBtn, st.searchCompany)}
                    type="button"
                    onClick={() => vm.openCompanySearchModal()}
                  >
                    법인조회 다시하기
                  </button>
                  {vm.process.registrationDateString && (
                    <div className={st.registrationDate}>{vm.process.registrationDateString}</div>
                  )}
                  {vm.process.lastStep && (
                    <div className={st.lastStep}>
                      현재상태 - <span className={st.step}>{vm.process.lastStep}</span>
                      {vm.process.lastStep !== '이용등록 진행취소' && (
                        <button type="button" onClick={() => vm.cancleProcess(businessId, vm.process.lastStep)}>
                          진행취소
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </li>
              <li>
                <div className={listSt.ListTitle} style={{ width: '190px' }}>
                  기장계약
                </div>
                <ul className={listSt.innerListContent}>
                  <li>
                    <div className={listSt.innerTitle} style={{ width: '144px' }}>
                      계약정보
                    </div>
                    <div className={listSt.innerContent}>
                      <ul className={listSt.rowContent}>
                        <li>
                          <div className={listSt.rowTitle}>고객유형</div>
                          <div className={listSt.rowContent}>
                            <ul className={listSt.radioList}>
                              <li>
                                <Radio
                                  name="customerType"
                                  value="기장대행_원천세O"
                                  checked={vm.bookkeepingServiceContract.customerType === '기장대행_원천세O'}
                                  onChange={vm.customerType}
                                >
                                  기장대행 - 원천세 O
                                </Radio>
                              </li>
                              <li>
                                <Radio
                                  name="customerType"
                                  value="기장대행_원천세X"
                                  checked={vm.bookkeepingServiceContract.customerType === '기장대행_원천세X'}
                                  onChange={vm.customerType}
                                >
                                  기장대행 - 원천세 X
                                </Radio>
                              </li>
                              <li>
                                <Radio
                                  name="customerType"
                                  value="신고대행"
                                  checked={vm.bookkeepingServiceContract.customerType === '신고대행'}
                                  onChange={vm.customerType}
                                >
                                  신고대행
                                </Radio>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className={clsx(listSt.rowTitle, listSt.inputMiddle)}>기장계약 개시일</div>
                          <div className={listSt.rowContent}>
                            <div className={st.datepicker}>
                              <DatePicker
                                name=""
                                selected={vm.bookkeepingServiceContract.bookkeepingServiceStartAt}
                                onChange={vm.bookkeepingServiceStartAt}
                              />
                            </div>
                            {vm.bookkeepingServiceContract?.freeMonths && (
                              <div style={{ marginTop: '8px' }}>
                                {vm.bookkeepingServiceContract?.freeMonths?.map((item, idx) => (
                                  <CheckBox
                                    checked={vm.bookkeepingServiceContract.freeMonth === item}
                                    value={item}
                                    onChange={(e) => vm.freeBookkeepingServiceYn(e)}
                                    key={idx}
                                    className={st.freeMonthCheckBox}
                                  >
                                    {item}개월 무료기장혜택
                                  </CheckBox>
                                ))}
                              </div>
                            )}
                          </div>
                        </li>
                        {vm.bookkeepingServiceContract.freeMonth && (
                          <li>
                            <div className={clsx(listSt.rowTitle, listSt.inputMiddle)}>무료기장 종료일</div>
                            <div className={listSt.rowContent}>
                              <div className={st.datepicker}>
                                <DatePicker
                                  name=""
                                  selected={vm.bookkeepingServiceContract.freeBookkeepingServiceEndAt}
                                  onChange={vm.freeBookkeepingServiceEndAt}
                                />
                              </div>
                            </div>
                          </li>
                        )}
                        <li>
                          <div className={clsx(listSt.rowTitle, listSt.multiRow)}>
                            월 기장료
                            <br />
                            (부가세 제외)
                          </div>
                          <div className={listSt.rowContent}>
                            <div>
                              <InputText
                                name=""
                                value={addCommas(vm.bookkeepingServiceContract.bookkeepingServiceFee)}
                                onChange={vm.bookkeepingServiceFee}
                                style={{ width: '298px', textAlign: 'right' }}
                              />
                              <span className={st.inputWon}>원</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className={clsx(listSt.rowTitle, listSt.inputMiddle)}>담당자 배정</div>
                          <div className={listSt.rowContent}>
                            <Select
                              name=""
                              style={{ width: '298px' }}
                              value={vm.bookkeepingServiceContract.bookkeepingStaffId}
                              onChange={vm.bookkeepingStaffId}
                            >
                              <option value="">-</option>
                              {vm.bookkeepingServiceContract.selectableBookkeepingStaffs.map(
                                (selectableBookkeepingStaff, index) => (
                                  <option key={index} value={selectableBookkeepingStaff.id}>
                                    {selectableBookkeepingStaff.name}
                                  </option>
                                ),
                              )}
                            </Select>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className={clsx(listSt.innerTitle, listSt.inputMiddle)} style={{ width: '144px' }}>
                      법인정보
                    </div>
                    <div className={listSt.innerContent}>
                      <ul className={listSt.rowContent}>
                        <li>
                          <div className={clsx(listSt.rowTitle, listSt.inputMiddle)}>과세/면세</div>
                          <div className={listSt.rowContent}>
                            <Select
                              name=""
                              style={{ width: '298px' }}
                              value={String(vm.company.taxationYn)}
                              onChange={vm.taxationYn}
                            >
                              <option value="">-</option>
                              <option value="false">면세</option>
                              <option value="true">과세</option>
                            </Select>
                          </div>
                        </li>
                        <li>
                          <div className={clsx(listSt.rowTitle, listSt.inputMiddle)}>성실/일반</div>
                          <div className={listSt.rowContent}>
                            <Select
                              name=""
                              style={{ width: '298px' }}
                              value={String(vm.company.faithfulnessTaxPayerYn)}
                              onChange={vm.faithfulnessTaxPayerYn}
                            >
                              <option value="">-</option>
                              <option value="false">일반</option>
                              <option value="true">성실</option>
                            </Select>
                          </div>
                        </li>
                        <li>
                          <div className={clsx(listSt.rowTitle, listSt.inputMiddle)}>급여지급일</div>
                          <div className={listSt.rowContent}>
                            <div style={{ display: 'flex', alignItem: 'center' }}>
                              <Select
                                name=""
                                value={String(vm.company.paydayThisMonthYn)}
                                onChange={vm.paydayThisMonthYn}
                                style={{ width: '145px' }}
                              >
                                <option value="">-</option>
                                <option value="false">익월</option>
                                <option value="true">당월</option>
                              </Select>
                              <Select
                                name=""
                                value={vm.company.payday}
                                onChange={vm.payday}
                                style={{ width: '145px', marginLeft: '8px' }}
                              >
                                <option value="">-</option>
                                {[...Array(parseInt(31))].map((n, index) => (
                                  <option key={index} value={index + 1}>
                                    {index + 1}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className={clsx(listSt.rowTitle, listSt.inputMiddle)}>부가세 예정신고</div>
                          <div className={listSt.rowContent}>
                            <Select
                              name=""
                              style={{ width: '298px' }}
                              value={vm.company.vatPreliminaryReturn}
                              onChange={vm.vatPreliminaryReturn}
                            >
                              <option value="">-</option>
                              {vm.company.selectableVatPreliminaryReturns.map(
                                (selectableVatPreliminaryReturn, index) => (
                                  <option key={index} value={selectableVatPreliminaryReturn.code}>
                                    {selectableVatPreliminaryReturn.title}
                                  </option>
                                ),
                              )}
                            </Select>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className={listSt.hasBtn}>
                    <div className={listSt.innerTitle} style={{ width: '144px' }}>
                      사업자등록 정보
                    </div>
                    <div className={listSt.innerContent}>
                      <ul className={listSt.rowContent}>
                        <li>
                          <div className={listSt.rowContent} style={{ marginTop: '4px' }}>
                            <CheckBox checked={vm.businessRegistration.unregisteredYn} onChange={vm.unregisteredYn}>
                              사업자등록 미등록상태
                            </CheckBox>
                          </div>
                        </li>
                        {vm.businessRegistration.unregisteredYn !== true && (
                          <>
                            <li style={{ marginTop: '16px' }}>
                              <div className={clsx(listSt.rowTitle, listSt.inputMiddle)}>사업자등록번호</div>
                              <div className={listSt.rowContent}>
                                <div>
                                  <InputText
                                    maxLength="12"
                                    name=""
                                    value={vm.businessRegistration.businessRegistrationNumber}
                                    onChange={vm.businessRegistrationNumber}
                                    style={{ width: '298px' }}
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className={clsx(listSt.rowTitle, listSt.inputMiddle)}>사업개시일</div>
                              <div className={listSt.rowContent}>
                                <div className={st.datepicker}>
                                  <DatePicker
                                    name=""
                                    selected={vm.businessRegistration.businessStartAt}
                                    onChange={vm.businessStartAt}
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className={clsx(listSt.rowTitle, listSt.inputMiddle)}>
                                업태, 종목,
                                <br /> 업종코드
                              </div>
                              <div className={listSt.rowContent} style={{ marginTop: '-12px' }}>
                                {vm.businessRegistration.businessTypeAndItemList.map((businessTypeAndItem, index) => (
                                  <div className={listSt.inputMany} key={index} style={{ width: '298px' }}>
                                    <div className={st.businessTypeAndItem}>
                                      <div className={st.type}>
                                        <span className={st.index}>{index + 1}.</span>
                                        <span>업태</span>
                                      </div>
                                      <InputText
                                        name=""
                                        value={businessTypeAndItem.type}
                                        onChange={(e) => vm.businessType(e, index)}
                                        style={{ width: '245px' }}
                                      />
                                    </div>
                                    <div className={st.businessTypeAndItem}>
                                      <span className={st.item}>종목</span>
                                      <InputText
                                        name=""
                                        value={businessTypeAndItem.item}
                                        onChange={(e) => vm.businessItem(e, index)}
                                        style={{ width: '245px' }}
                                      />
                                    </div>
                                    <div className={st.businessTypeAndItem}>
                                      <span className={st.item}>업종코드</span>
                                      <InputText
                                        name=""
                                        value={businessTypeAndItem.code}
                                        onChange={(e) => vm.businessCode(e, index)}
                                        style={{ width: '245px' }}
                                      />
                                    </div>
                                    <PlusBtn className={listSt.plus} onClick={() => vm.addBusinessTypeAndItem(index)} />
                                    <MinusBtn
                                      className={listSt.minus}
                                      onClick={() => vm.removeBusinessTypeAndItem(index)}
                                      disabled={vm.businessRegistration.businessTypeAndItemList.length <= 1}
                                    />
                                  </div>
                                ))}
                              </div>
                            </li>
                            <li>
                              <div className={clsx(listSt.rowTitle, listSt.multiRow)}>
                                사업자등록증
                                <br />
                                발급일
                              </div>
                              <div className={listSt.rowContent}>
                                <div className={st.datepicker}>
                                  <DatePicker
                                    name=""
                                    selected={vm.businessRegistration.issuedAt}
                                    onChange={vm.issuedAt}
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className={clsx(listSt.rowTitle, listSt.multiRow)}>
                                사업자등록증
                                <br />
                                업로드
                              </div>
                              <div className={listSt.rowContent}>
                                <DragFileUpload
                                  uploadFile={vm.businessRegistration.businessRegistrationFile}
                                  changeUploadFile={vm.changeBusinessRegistrationFile}
                                  uploadBtnText={
                                    <>
                                      <strong>사업자등록증</strong> 업로드
                                    </>
                                  }
                                />
                              </div>
                            </li>
                          </>
                        )}
                        <li>
                          <div className={clsx(listSt.rowTitle, listSt.multiRow)}>알림톡 받으실 분</div>
                          <div className={listSt.rowContent}>
                            <ul className={st.checkList}>
                              {vm.process.delegationRegistrationAlimTalkToList.map((person, index) => (
                                <li key={index}>
                                  <CheckBox
                                    checked={person.checkedYn}
                                    onChange={(e) => vm.delegationRegistrationAlimTalkToList(e, index)}
                                  >
                                    {person.position ? `${person.position} : ` : ''} {person.name}님
                                  </CheckBox>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className={listSt.hasBtn} style={{ paddingBottom: '18px' }}>
                    <div>
                      {partner.type === partner.진평 && (
                        <button
                          style={{ width: '284px' }}
                          type="button"
                          disabled={!vm.delegationRegistrationActive}
                          className={listSt.sendBtn}
                          onClick={() =>
                            vm.sendDelegationRegistration({ delegationRegistrationOnlyYn: true }, businessId)
                          }
                        >
                          <span>이용등록만 하기</span>
                        </button>
                      )}
                      <button
                        style={{ width: '284px', marginLeft: '8px' }}
                        type="button"
                        disabled={!vm.delegationRegistrationActive}
                        className={listSt.sendBtn}
                        onClick={() =>
                          vm.sendDelegationRegistration({ delegationRegistrationOnlyYn: false }, businessId)
                        }
                      >
                        <span>이용등록 + 수임계약서 날인 요청하기</span>
                      </button>
                      <div className={st.messageBox}>
                        {partner.type === partner.진평 && (
                          <MessageHint text="알림톡 메세지보기" style={{ width: '284px', display: 'inline-block' }}>
                            <img src="/images/talkPreview_이용등록만.png" />
                          </MessageHint>
                        )}
                        <MessageHint
                          text="알림톡 메세지보기"
                          style={{ width: '284px', display: 'inline-block', marginLeft: '8px' }}
                        >
                          <img src="/images/talkPreview_이용등록_수임계약서.png?2" />
                        </MessageHint>
                      </div>
                      {partner.type === partner.진평 && (
                        <div className={st.ex}>* 계약서를 아직 작성하지 않았다면 오른쪽 버튼을 누르세요!</div>
                      )}
                    </div>
                    <div className={listSt.saveFixBtn}>
                      <div className={listSt.btn}>
                        <button
                          type="button"
                          disabled={!vm.delegationRegistrationActive}
                          onClick={() => vm.saveDelegationRegistration(businessId)}
                        >
                          수정사항 저장
                        </button>
                      </div>
                      <div className={clsx(listSt.ex, !vm.delegationRegistrationActive ? listSt.disabled : null)}>
                        저장시 고객화면 반영
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <div className={listSt.ListTitle} style={{ width: '190px' }}>
                  CMS 계좌등록
                </div>
                <div className={listSt.listContent}>
                  <ul className={listSt.flexList}>
                    <li className={listSt.hasBtn}>
                      <div className={listSt.rowTitle}>알림톡 받으실 분</div>
                      <div className={listSt.rowContent}>
                        <ul className={st.checkList}>
                          {vm.process.cmsAlimTalkToList.map((person, index) => (
                            <li key={index}>
                              <CheckBox checked={person.checkedYn} onChange={(e) => vm.cmsAlimTalkToList(e, index)}>
                                {person.position ? `${person.position} : ` : ''} {person.name}님
                              </CheckBox>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div>
                        <button
                          style={{ width: '356px' }}
                          type="button"
                          disabled={!vm.cmsActive || !vm.process.cmsAccountButtonActiveYn}
                          className={listSt.sendBtn}
                          onClick={() => vm.sendCmsAlimTalk(businessId)}
                        >
                          <span>CMS계좌등록 + 계약서작성 알림톡 발송</span>
                        </button>
                        <MessageHint text="알림톡 메세지보기">
                          <img src="/images/talkPreview_CMS.png?1" />
                        </MessageHint>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )
    ) : (
      <div className={listSt.checkResultList}>
        <div className={listSt.result}>
          <div className={listSt.title}>이용등록</div>
          <ul>
            <li>
              <div className={listSt.ListTitle}>법인조회</div>
              <div className={clsx(listSt.listContent, st.businessIdEmpty)}>
                <div className={st.text}>이용등록하실 법인을 조회하세요!</div>
                <button className={st.searchBtn} type="button" onClick={() => vm.openCompanySearchModal()}>
                  조회하기
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    ),
  );
}

export default Process;
